import React, { useMemo, useState, useEffect, useRef, useCallback, useContext } from "react";
import { createStore, createDirectLine, Components } from "botframework-webchat";
import { Flex, Text, Dialog } from "@fluentui/react-northstar";
import { LoaderHelper } from "./LoaderHelper";
import { fetchFromBackend } from "../lib/makeBackendCall";
import "./CyberCoachChat.css";
import { hooks } from "botframework-webchat-component";
import { isDarkTheme } from "../lib/chartTheme";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { ClientContext } from "../App";

const { useSendMessage } = hooks;

export function CyberCoachChat(props) {
  const showChat = props.show;
  const userName = props.userName;
  const accessToken = props.accessToken;
  const darkMode = isDarkTheme(props.themeString);
  const [showTeamsWarning, setShowTeamsWarning] = useState(props.inTeams);
  const [webChatCreds, setWebChatCreds] = useState();
  const [chatStarted, setChatStarted] = useState();
  const { t } = useTranslation();
  const [chatLoaded, setChatLoaded] = useState(false);
  const [menuLoaded, setMenuLoaded] = useState(false);
  const [botCommand, setBotCommand] = useState(props.command);
  const { clientInfo } = useContext(ClientContext);

  useMemo(() => {
    setBotCommand(props.command);
    setWebChatCreds();
    setChatLoaded(false);
    setChatStarted(false);
    setMenuLoaded(false);
  }, [props.command]);

  const previousButtonRef = useRef(null);
  const myRef = useRef(null);

  useEffect(() => {
    let refValue = myRef.current;
    if (refValue) {
      refValue.addEventListener("mouseup", handleClick);
      return () => {
        refValue.removeEventListener("mouseup", handleClick);
      };
    } else {
      return;
    }
  }, []);

  const handleClick = (event) => {
    //check if button is clicked
    //if user presses directly on text target is not button so check if parent is button
    if (event.target.classList.contains("ac-pushButton") || event.target.parentElement.classList.contains("ac-pushButton")) {
      const target = event.target.classList.contains("ac-pushButton") ? event.target : event.target.parentElement;
      target.classList.add("clickedButton");
      previousButtonRef.current = target;
    }
  };

  useEffect(() => {
    async function fetchWebChatCreds() {
      const [data, statusCode] = await fetchFromBackend(accessToken, "webchat-auth/", "POST", { userName: userName });
      if (statusCode === 200) {
        const userLanguage = await fetchFromBackend(accessToken, "user/userLanguage");
        data.userLanguage = userLanguage ? userLanguage : "en";
        setWebChatCreds(data);
      } else {
        setWebChatCreds(false);
      }
    }

    !webChatCreds && fetchWebChatCreds();
  }, [accessToken, userName, webChatCreds, botCommand]);

  const directLine = useMemo(() => {
    if (!webChatCreds) {
      return undefined;
    }
    return createDirectLine({ token: webChatCreds.botToken.token });
  }, [webChatCreds]);

  const customWebChatStore = useMemo(
    () =>
      createStore({}, ({ dispatch }) => (next) => (action) => {
        if (action.type === "DIRECT_LINE/CONNECT_FULFILLED") {
          dispatch({
            type: "WEB_CHAT/SEND_EVENT",
            payload: {
              name: "webchat/join",
              value: { language: webChatCreds.userLanguage },
            },
          });
        } else if (action.type === "DIRECT_LINE/POST_ACTIVITY") {
          const actionWithCCBUserInfo = {
            ...action,
            payload: {
              ...action.payload,
              activity: {
                ...action.payload.activity,
                channelData: {
                  ...action.payload.activity.channelData,
                  extendedCcbUserInfo: {
                    payload: webChatCreds.extendedUserInfo,
                    signature: webChatCreds.extendedUserInfoSignature,
                  },
                  extraInfo: {
                    clientInfo: clientInfo?.client,
                  },
                },
              },
            },
          };
          if (previousButtonRef.current) {
            previousButtonRef.current.disabled = true;
            setTimeout(() => {
              previousButtonRef.current.disabled = false;
            }, 3000);
          }
          // short circuit out of modifying this DIRECT_LINE/POST_ACTIVITY action,
          // we've modified the action, let's pass the modified version along
          return next(actionWithCCBUserInfo);
        } else if (
          action.type === "WEB_CHAT/SET_SUGGESTED_ACTIONS" &&
          document.getElementsByClassName("webchat__basic-transcript__activity").length === 1 &&
          document.getElementsByClassName("webchat__basic-transcript__activity")[0] &&
          document.getElementsByClassName("webchat__basic-transcript__activity")[0].hidden !== true
        ) {
          setChatLoaded(true);
          document.getElementsByClassName("webchat__basic-transcript__activity")[0].hidden = true;
        } else if (
          action.type === "DIRECT_LINE/QUEUE_INCOMING_ACTIVITY" &&
          document.getElementsByClassName("webchat__basic-transcript__activity")[1] &&
          document.getElementsByClassName("webchat__basic-transcript__activity")[1].hidden !== true
        ) {
          document.getElementsByClassName("webchat__basic-transcript__activity")[1].hidden = true;
        } else if (
          action.type === "DIRECT_LINE/QUEUE_INCOMING_ACTIVITY" &&
          document.getElementsByClassName("webchat__basic-transcript__activity").length >= 3 &&
          document.getElementById("chatFlex").hidden
        ) {
          setMenuLoaded(true);
          if (botCommand) {
            if (
              document.getElementsByClassName("webchat__basic-transcript__activity")[2] &&
              document.getElementsByClassName("webchat__basic-transcript__activity")[2].hidden !== true
            ) {
              document.getElementsByClassName("webchat__basic-transcript__activity")[2].hidden = true;
            }
          }
        } else if (
          action.type === "WEB_CHAT/SET_SUGGESTED_ACTIONS" &&
          document.getElementsByClassName("webchat__basic-transcript__activity")[3] &&
          document.getElementsByClassName("webchat__basic-transcript__activity")[4] &&
          document.getElementById("chatFlex").hidden
        ) {
          document.getElementsByClassName("webchat__basic-transcript__activity")[3].hidden = true;
          document.getElementsByClassName("webchat__basic-transcript__activity")[4].hidden = true;
          setChatStarted(true);
        }
        // we did not care about this action, pass it along
        return next(action);
      }),
    [webChatCreds, botCommand, clientInfo]
  );

  const isSmall = useMediaQuery(
    { maxWidth: 700 } // `device` prop
  );

  const styleOptions = {
    botAvatarInitials: isSmall ? "" : "CC",
    botAvatarImage: isSmall ? "" : "img/bot-teams-color-purple.png",
    backgroundColor: "none",
    bubbleFromUserBackground: darkMode ? "rgb(147, 153, 245,0.3)" : "#f5f7fa",
    bubbleBackground: darkMode ? "#292929" : "#F5F5F5",
    avatarBorderRadius: "50%",
    avatarSize: 40,
    sendBoxHeight: 40,
    showAvatarInGroup: "status",
    hideUploadButton: true,
    bubbleBorderRadius: 24,
    bubbleFromUserBorderRadius: 24,
    bubbleTextColor: darkMode ? "white" : "black",
    bubbleFromUserTextColor: darkMode ? "white" : "black",
    bubbleFromUserBorderStyle: darkMode ? "none" : "solid",
    bubbleBorderStyle: darkMode ? "none" : "solid",
    sendBoxBackground: darkMode ? "#292929" : "#f9f9f9",
    sendBoxTextColor: darkMode ? "white" : "black",
  };

  const SendCommandButton = () => {
    const sendMessage = useSendMessage();
    const handleHelpButtonClick = useCallback(() => sendMessage(menuLoaded ? botCommand : "continue"), [sendMessage]);
    return <button hidden id="commandButton" onClick={handleHelpButtonClick} type="button"></button>;
  };

  useEffect(() => {
    if (chatLoaded && !menuLoaded && !chatStarted && document.getElementById("commandButton")) {
      document.getElementById("commandButton").click();
    }
  }, [chatLoaded, menuLoaded, chatStarted]);

  useEffect(() => {
    if (menuLoaded && document.getElementById("commandButton") && chatLoaded) {
      if (botCommand) {
        document.getElementById("commandButton").click();
      } else {
        setChatStarted(true);
      }
    }
  }, [menuLoaded, botCommand, chatLoaded]);

  return (
    <Flex
      hAlign="center"
      column
      vAlign="center"
      styles={{ height: "100%", display: showChat ? "flex" : "none", margin: "auto", overflow: "auto" }}
      className="chatWrapper"
      gap="gap.small"
      ref={myRef}
    >
      <Dialog
        confirmButton={"OK"}
        content={
          <Flex column gap="gap.small">
            <Text content={t("CyberCoachChat.teamsWarningl1")} />
            <Text weight="semibold" content={t("CyberCoachChat.teamsWarningl2")} />
          </Flex>
        }
        header={t("CyberCoachChat.teamsWarningHeader")}
        onConfirm={() => setShowTeamsWarning(false)}
        open={showTeamsWarning && showChat}
      />

      {directLine !== undefined && webChatCreds && !chatStarted && (
        <LoaderHelper header={t("CyberCoachChat.gettingReady")} text={t("CyberCoachChat.startingChat")} />
      )}
      {directLine !== undefined && webChatCreds && (
        <Components.Composer
          directLine={directLine}
          userID={webChatCreds.userId}
          username={webChatCreds.userName}
          styleOptions={styleOptions}
          store={customWebChatStore}
        >
          <Flex
            column
            gap="gap.small"
            fill
            hAlign="stretch"
            vAlign="end"
            hidden={!chatStarted}
            styles={{ height: "90%", width: "100%", minHeight: "90%" }}
            id="chatFlex"
          >
            <Components.BasicTranscript styles={{ height: "100%" }} />
            <Components.BasicSendBox />
          </Flex>
          <SendCommandButton />
        </Components.Composer>
      )}
      {directLine === undefined && webChatCreds !== false && (
        <LoaderHelper header={t("CyberCoachChat.authenticating")} text={t("CyberCoachChat.chatOpening")} showLoader={true} />
      )}
      {webChatCreds === false && <LoaderHelper header="Something went wrong :(" text="Failed to open CyberCoach chat" />}
    </Flex>
  );
}
